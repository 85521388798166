import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PrimengModule } from './modules/primeng/primeng.module';

import { FormComponent } from './components/form/form.component';
import { ControlComponent } from './components/control/control.component'
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { MaskPipe } from './pipes/mask.pipe';
import { LoadingComponent } from './components/loading/loading.component';


const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  PrimengModule,
];

const components = [
  FormComponent,
  ControlComponent,
  AuthLayoutComponent,
  LoadingComponent
];

const pipes = [
  MaskPipe
]

@NgModule({
  imports: modules,
  declarations: [...components, ...pipes],
  entryComponents: components,
  exports: [...modules, ...components, ...pipes]
})
export class CoreModule { }
