export abstract class IFunctionConfigService<T> {
  /**
   * Get menu config from config file
   * @returns function config object
   */
  abstract getMenuConfig(): T

  /**
   * Get function name by function id.
   * @param functionId function id
   * @returns function name
   **/
  abstract getFunctionNameByFunctionId(functionId: string): string

  /**
   * Check function permission by function id.
   * @param functionId function id
   * @returns true if function have permission, otherwise false
   **/
  abstract isFunctionAvailable(functionId: string): boolean
}
