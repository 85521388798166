import { Component, Injector } from '@angular/core'

import { CoreService } from './core-service'
@Component({
  template: ''
})
export abstract class CoreComponent extends CoreService {

  constructor(injector: Injector) {
    super(injector)
  }

  protected showLoading() {
    Promise.resolve().then(() => this.app.loading = true)
  }

  protected hideLoading() {
    Promise.resolve().then(() => this.app.loading = false)
  }

}
