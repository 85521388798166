import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { ProjectModel } from 'src/app/core/domain/project.model'
import { IProjectRepository } from 'src/app/core/repositories/project.repository'

@Injectable()
export class ProjectMockRepository extends IProjectRepository {
  projects: ProjectModel[] = [
    {
      id: 1,
      name: 'Project 1',
      firstName: 'John',
      lastName: 'Doe',
      email: 'john.d@example.com',
      dateTime: new Date('2022-02-11 00:00:00'),
      status: 'Approved',
      files: [],
    },
    {
      id: 2,
      name: 'Project 2',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.d@example.com',
      dateTime: new Date('2022-02-11 00:00:00'),
      status: 'Pending',
      files: [],
    },
    {
      id: 3,
      name: 'Project 3',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.d@example.com',
      dateTime: new Date('2022-02-14 00:00:00'),
      status: 'Cancel',
      files: [],
    },
    {
      id: 4,
      name: 'Project 4',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.d@example.com',
      dateTime: new Date('2022-02-15 00:00:00'),
      status: 'Hold',
      files: [],
    },
    {
      id: 5,
      name: 'Project 5',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.d@example.com',
      dateTime: new Date('2022-02-16 00:00:00'),
      status: 'Pending',
      files: [],
    },
    {
      id: 6,
      name: 'Project 6',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.d@example.com',
      dateTime: new Date('2022-02-17 00:00:00'),
      status: 'Pending',
      files: [],
    },
    {
      id: 7,
      name: 'Project 7',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.d@example.com',
      dateTime: new Date('2022-02-18 00:00:00'),
      status: 'Pending',
      files: [],
    },
    {
      id: 8,
      name: 'Project 8',
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.d@example.com',
      dateTime: new Date('2022-02-18 00:00:00'),
      status: 'Pending',
      files: [],
    },
  ]

  constructor() {
    super()
  }

  getAllProjects(options: any = null): Observable<ProjectModel[]> {
    if (options) {
      const filteredProjects = this.projects.filter((project) => {
        let matched = true
        if (options.projectId && options.projectId > 0 && options.projectId !== project.id) {
          matched = false
        }
        if (options.email && options.email.length && !project.email.match(new RegExp(options.email, 'i'))) {
          matched = false
        }
        if (options.status && options.status.length && options.status !== project.status) {
          matched = false
        }
        if (
          options.dateTime &&
          typeof options.dateTime == 'object' &&
          options.dateTime.getTime() !== project.dateTime.getTime()
        ) {
          matched = false
        }
        if (
          options.firstName &&
          options.firstName.length &&
          !project.firstName.match(new RegExp(options.firstName, 'i'))
        ) {
          matched = false
        }
        if (options.lastName && options.lastName.length && !project.lastName.match(new RegExp(options.lastName, 'i'))) {
          matched = false
        }
        return matched
      })
      return of(filteredProjects)
    } else return of(this.projects)
  }

  getProjectById(id: number): Observable<ProjectModel> {
    return of(this.projects).pipe(map((projects: ProjectModel[]) => projects.find((project) => project.id === id)))
  }

  createProject(project: ProjectModel): Observable<ProjectModel> {
    let id = this.projects[this.projects.length - 1].id + 1
    const newProject: ProjectModel = {
      id: id,
      ...project,
    }
    this.projects.push(newProject)
    return of(newProject)
    // throw new Error('Method not implemented.')
  }

  updateProject(id: number, project: ProjectModel): Observable<ProjectModel> {
    let currentProjectIndex = this.projects.findIndex((proj) => proj.id === id)
    if (currentProjectIndex > -1) {
      this.projects[currentProjectIndex] = {
        id: id,
        ...project,
      }
      return of(this.projects[currentProjectIndex])
    } else {
      throw new Error('Project not found.')
    }
    // throw new Error('Method not implemented.')
  }

  deleteProject(ids: number[]): Observable<ProjectModel[]> {
    let deletedProjects: ProjectModel[] = []
    ids.forEach((id) => {
      let currentProjectIndex = this.projects.findIndex((proj) => proj.id === id)
      if (currentProjectIndex > -1) {
        const deletedProject = this.projects.splice(currentProjectIndex, 1)
        deletedProjects.push(deletedProject[0])
      } else {
        throw new Error('Project not found.')
      }
    })
    return of(deletedProjects)
    // throw new Error('Method not implemented.')
  }
}
