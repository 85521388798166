import { Observable } from 'rxjs'
import { ProjectModel } from '../domain/project.model'

export abstract class IProjectRepository {
  abstract getAllProjects(options?: any): Observable<ProjectModel[]>
  abstract getProjectById(id: number): Observable<ProjectModel>
  abstract createProject(project: ProjectModel): Observable<ProjectModel>
  abstract updateProject(id: number, project: ProjectModel): Observable<ProjectModel>
  abstract deleteProject(ids: number[]): Observable<ProjectModel[]>
}
