import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ProjectModel } from '../core/domain/project.model'
import { IProjectRepository } from '../core/repositories/project.repository'
import { IProjectService } from '../core/service/project.service'

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends IProjectService {
  constructor(private projectRepository: IProjectRepository) {
    super()
  }

  getAllProjects(options: any = null): Observable<ProjectModel[]> {
    return this.projectRepository.getAllProjects(options)
  }

  getProjectById(id: number): Observable<ProjectModel> {
    return this.projectRepository.getProjectById(id)
  }

  createProject(project: ProjectModel): Observable<ProjectModel> {
    // throw new Error('Method not implemented.')
    return this.projectRepository.createProject(project)
  }

  updateProject(id: number, project: ProjectModel): Observable<ProjectModel> {
    // throw new Error('Method not implemented.')
    return this.projectRepository.updateProject(id, project)
  }

  deleteProject(ids: number[]): Observable<ProjectModel[]> {
    // throw new Error('Method not implemented.')
    return this.projectRepository.deleteProject(ids)
  }
}
