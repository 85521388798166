import { NgModule } from '@angular/core';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SidebarModule } from 'primeng/sidebar';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {PasswordModule} from 'primeng/password';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {InputMaskModule} from 'primeng/inputmask';
import {DividerModule} from 'primeng/divider';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

const modules = [
  BreadcrumbModule,
  SidebarModule,
  TieredMenuModule,
  PasswordModule,
  InputTextModule,
  CheckboxModule,
  ButtonModule,
  InputMaskModule,
  DividerModule,
  ConfirmDialogModule,
];

@NgModule({
  imports: modules,
  exports: modules
})
export class PrimengModule { }
