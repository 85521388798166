import { Injectable } from '@angular/core'
import { StandardToolConfigModel } from '../core/domain/standard-tool-config.model'
import { IStandardToolConfigRepository } from '../core/repositories/standard-tool-config.repository'
import { IStandardToolConfigService } from '../core/service/standard-tool-config.service'

@Injectable({
  providedIn: 'root',
})
export class StandardToolConfigService extends IStandardToolConfigService<StandardToolConfigModel> {
  constructor(private standardToolConfigRepository: IStandardToolConfigRepository<StandardToolConfigModel>) {
    super()
  }

  getStandardToolConfig(): StandardToolConfigModel[] {
    return this.standardToolConfigRepository.getStandardToolConfig()
  }

  isFunctionAvailable(functionId: string): boolean {
    return this.standardToolConfigRepository.isFunctionAvailable(functionId)
  }
}
