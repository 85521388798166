import { EventEmitter, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { AxAuthenticationConfig, AxAuthenticationService } from '@atlasx/core/authentication';
import { AxFileService, AxRequestService } from '@atlasx/core/http-service';
import { environment } from 'src/environments/environment';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { ConfigurationService } from 'src/app/service/configuration.service';
import { AppService } from 'src/app/app.service';

export class CoreService {

  private http: HttpClient;

  protected app: AppService;
  protected auth: AxAuthenticationService;
  protected authConfig: AxAuthenticationConfig;
  protected config: ConfigurationService;
  protected request: AxRequestService;
  protected file: AxFileService;
  protected confirmationService: ConfirmationService
  protected webServiceUrl: string

  protected router: Router;

  constructor(injector: Injector) {

    this.http = injector.get(HttpClient);

    this.app = injector.get(AppService);
    this.auth = injector.get(AxAuthenticationService);
    this.authConfig = injector.get(AxAuthenticationConfig);
    this.config = injector.get(ConfigurationService);
    this.request = injector.get(AxRequestService);
    this.file = injector.get(AxFileService);
    this.confirmationService = injector.get(ConfirmationService);
    this.webServiceUrl = environment.webServiceUrl

    this.router = injector.get(Router);
  }

  protected async reqPost(url: string, params: any = {}): Promise<any> {
    const formData = new FormData();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }
    return this.http.post<any>(url, formData).pipe(take(1)).toPromise();
  }

  protected async reqSP(spName: string, params: any = {}) {
    params['APP_DATA_PROCEDURE'] = spName;
    try {
      const webServiceUrl = environment.webServiceUrl;
      return await this.reqPost(`${webServiceUrl}/api/appdata`, params);
    } catch (exception) {
      if (exception.error) {
        return exception.error
      } else {
        return { success: false, message: exception }
      }
    }
  }

  protected async upload(fileParams: { [key: string]: File; } | FileList, filePath?: string, fileSource?: string) {
    try {
      return await this.file.upload(fileParams, filePath, fileSource).toPromise();
    } catch (exception) {
      return { success: false, message: exception }
    }
  }

  protected async remove(fileIds?: string[], filePath?: string, fileSource?: string) {
    try {
      return await this.file.remove(fileIds, filePath, fileSource).toPromise();
    } catch (exception) {
      return { success: false, message: exception }
    }
  }

  protected download(fileId: string, filePath?: string, fileSource?: string): string {
    return this.file.download(fileId, filePath, fileSource);
  }

  protected preview(fileId: string, filePath?: string, fileSource?: string): string {
    return this.file.preview(fileId, filePath, fileSource);
  }

  configLookupQ: string[];
  lookup: any;
  public async loadLookup() {
    this.lookup = {};

    for (const config of this.configLookupQ) {
      const response = await this.reqSP(config);
      if (response.success) {
        this.lookup[config] = response['data'];
      }
    }
  }

  protected alert(message: string, type: 'info' | 'warning' = 'info', closeOnEscape = false) {
    const accept = new EventEmitter<void>()
    const options: Confirmation = {
      message,
      accept: () => accept.next(),
      reject: () => accept.next()
    }

    if (type === 'warning') {
      options.icon = 'pi pi-exclamation-triangle'
    }

    this.confirmationService.confirm(options)

    return accept
  }

}
