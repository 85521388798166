import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'

import { AxAuthenticationModule } from '@atlasx/core/authentication'
import { AxConfigurationModule } from '@atlasx/core/configuration'
import { AxHttpServiceModule, AxWebServiceUrl } from '@atlasx/core/http-service'

import { AppComponent } from './app.component'

import { environment } from '../environments/environment'

import { DataModule } from './data/data.module'
import { ServiceModule } from './service/service.module'
import { NgcCookieConsentModule } from 'ngx-cookieconsent'
import { cookieConfigJson } from './core/config/cookies-consent.config'
import { MarkdownModule } from 'ngx-markdown'
import { CoreModule } from './core/core.module'
import { ConfirmationService } from 'primeng/api'


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    AxHttpServiceModule,

    // Required register, if application use AtlasX configuration pattern.
    // It will load configuration before application initial startup.
    // AxConfigurationModule,

    // Required register, if application use authentication.
    AxAuthenticationModule.forRoot(environment),

    DataModule,
    ServiceModule,
    NgcCookieConsentModule.forRoot(cookieConfigJson),
    MarkdownModule.forRoot(),
    BrowserAnimationsModule,
    CoreModule
  ],
  providers: [
    // Required register, if application use AxAuthenticationModule or AxConfigurationModule.
    { provide: AxWebServiceUrl, useValue: environment.webServiceUrl },
    ConfirmationService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
