import { StandardToolConfigModel } from '../domain/standard-tool-config.model'

export const standardToolConfig: StandardToolConfigModel[] = [
  {
    type: 'fullscreen',
    label: 'Fullscreen',
  },
  {
    type: 'measurement',
    label: 'Measurement',
    iconClass: 'esri-icon-measure',
  },
  {
    type: 'layers',
    label: 'Layer',
    iconClass: 'esri-icon-layers',
  },
  {
    type: 'list',
    label: 'List',
    iconClass: 'esri-icon-layer-list',
  },
  {
    functionId: 'GIS-06',
    type: 'edit',
    label: 'Edit',
    iconClass: 'esri-icon-edit',
  },
  {
    type: 'print',
    label: 'Print',
    iconClass: 'esri-icon-printer',
  },
]
