export abstract class IStandardToolConfigService<T> {
  /**
   * Get standard tool config from config file
   * @returns array of standard tool config
   */
  abstract getStandardToolConfig(): T[]

  /**
   * Check function permission by function id.
   * @param functionId function id
   * @returns true if function have permission, otherwise false
   **/
  abstract isFunctionAvailable(functionId: string): boolean
}
