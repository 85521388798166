import { Injectable } from '@angular/core'
import { AxConfigurationService, AxFunctionAttributes } from '@atlasx/core/configuration'
import { AxRequestService, AxResponse } from '@atlasx/core/http-service'
import { map } from 'rxjs/operators'
import { menuConfig } from 'src/app/core/config/menu.config'
import { MenuConfigModel } from 'src/app/core/domain/menu-config.model'
import { IFunctionConfigRepository } from 'src/app/core/repositories/function-config.repository'
import { ConfigurationService } from 'src/app/service/configuration.service'

@Injectable()
export class FunctionConfigRepository extends IFunctionConfigRepository<MenuConfigModel> {
  private functionInfo: AxFunctionAttributes[] = []

  constructor(private configService: ConfigurationService, private requestService: AxRequestService) {
    super()
    this.loadFunctionInfoAsync()
  }

  private loadFunctionInfoAsync = async () => {
    // TODO: Check duplicate function id in functionInfo before concat

    this.functionInfo = [...this.configService.functions]
    const parentFunctionInfo = await this.getParentFunctionInfo()
    this.functionInfo = this.functionInfo.concat(parentFunctionInfo)
  }

  private async getParentFunctionInfo(): Promise<AxFunctionAttributes[]> {
    const parentFunctionIdList = new Set<string>()
    this.configService.functions.forEach((f) => {
      if (f.FUNCTION_PARENT) {
        parentFunctionIdList.add(f.FUNCTION_PARENT)
      }
    })
    const parentFunctionIds = Array.from(parentFunctionIdList)
    const params = {
      FUNCTION_ID_LIST: parentFunctionIds.join('^'),
    }
    return await this.requestService
      .sp<AxResponse<AxFunctionAttributes>>('APP_FUNCTIONS_INFO_Q', 'GET', params)
      .pipe(map((res) => res.data))
      .toPromise()
  }

  /**
   * Get all function id list that have permission to access.
   * include parent function id.
   * @returns function id list
   **/
  private getFullFunctionIdList(): string[] {
    const functionIdList = new Set<string>()
    this.configService.functions.forEach((f) => {
      if (f.FUNCTION_PARENT) {
        functionIdList.add(f.FUNCTION_PARENT)
      }
      functionIdList.add(f.FUNCTION_ID)
    })
    return Array.from(functionIdList)
  }

  getMenuConfig(): MenuConfigModel {
    return menuConfig
  }
  getFunctionNameByFunctionId(functionId: string): string {
    return this.functionInfo.find((f) => f.FUNCTION_ID === functionId)?.FUNCTION_NAME
  }
  isFunctionAvailable(functionId: string): boolean {
    return this.getFullFunctionIdList().includes(functionId)
  }
}
