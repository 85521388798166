import { Injectable } from '@angular/core'
import { AxRequestService, AxResponse } from '@atlasx/core/http-service'
import { map } from 'rxjs/operators'

import { IConsentRepository } from 'src/app/core/repositories/consent.repository'
import { PolicyInfo, PolicyType } from 'src/app/core/model/consent'


@Injectable()
export class ConsentRepository extends IConsentRepository {

  constructor(private axRequest: AxRequestService) {
    super()
  }

  getPolicy(policyType: PolicyType): Promise<PolicyInfo> {
    return this.axRequest.sp<AxResponse<PolicyInfo>>('APP_POLICY_Q', 'GET', { POLICY_TYPE: policyType })
      .pipe(map((result => result.data[0])))
      .toPromise()
  }

}
