import { AxAuthenticationConfig } from '@atlasx/core/authentication'
import { ApplicationConfig } from '../app/core/config/application.config'

export const environment: ApplicationConfig & AxAuthenticationConfig = {
  production: true,
  webServiceUrl: 'https://bmatax.bangkok.go.th',

  // OAuth client information.
  clientId: 'bmatax-portal',
  clientSecret: '02FZf68/Xff0hrSQgc7Je5CNgdjaSgltNU4gb3AbJ9A=',

  // When use full secure authentication, application are required
  // the callbackUri property.
  // fullSecureAuthentication: true,
  callbackUri: 'https://bmatax.bangkok.go.th/auth/callback',

  // If not use full secure authentication, application are required
  // the loginUri property.
  fullSecureAuthentication: false,
  loginUri: 'https://bmatax.bangkok.go.th/auth/login',

  // Password constraints config
  passwordConstraints: {
    includeUpperCase: true,
    includeLowerCase: true,
    includeNumber: true,
    includesSpecial: false,
    minLength: 8,
    maxLength: 16,
  },

  endpoints: {
    auth: {
      verify: '/api/appauthen/verify',
      savePassword: '/api/appauthen/password'
    }
  },

  useSSOAuth: false,
  ssoUrl: 'http://bmasso.bma.go.th'
}
