import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core'
import { filter, take } from 'rxjs/operators'
import { NavigationEnd, RouterEvent } from '@angular/router'
import { isPlatformBrowser } from '@angular/common'
import { AppService } from './app.service'
import { CoreComponent } from './core/class/core-component'
import { of } from 'rxjs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent extends CoreComponent implements OnInit, OnDestroy {

  get loading() {
    return this.app.loading
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    injector: Injector
  ) {
    super(injector)
    this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe((event) => {
      if (isPlatformBrowser(this.platformId)) {
        window.scroll(0, 0)
      }
    })
  }

  ngOnInit() {
    this.app.checkCookiesConsent()

    of(null)
      .pipe(
        // ทำงานเมื่อมี Token ใน Session Storage
        filter(() => this.auth.tokenResponseStorage !== null),
        filter(() => !this.auth.isAuthenticated()),
        take(1)
      )
      .subscribe(() => {
        this.auth.refreshTokenRequest()
          .catch(() => {
            this.alert('Session หมดอายุ ล็อกอินเพื่อใช้งานระบบ').subscribe(() => {
              this.auth.signOut()
            })
          })
      })

    // Trigger เพื่อดูว่า Refresh Token หมดอายุหรือยัง ทุก 10 วินาที
    // const sessionExpired = new Subject()
    // timer(0, 10000)
    //   .pipe(
    //     // ทำงานเมื่อมี Token ใน Session Storage
    //     filter(() => this.auth.tokenResponseStorage !== null),
    //     filter(() => !this.auth.isAuthenticated()),
    //     takeUntil(sessionExpired)
    //   )
    //   .subscribe(() => {
    //     this.auth.refreshTokenRequest()
    //       .catch(() => {
    //         this.alert('Session หมดอายุ ล็อกอินเพื่อใช้งานระบบ').subscribe(() => {
    //           this.auth.signOut()
    //         })

    //         // ยกเลิก Trigger
    //         sessionExpired.next()
    //         sessionExpired.complete()
    //       })
    //   })
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.app.destroyCookiesConsent()
  }
}
