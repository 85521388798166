import { NgcCookieConsentConfig } from 'ngx-cookieconsent'
// For more information, see: https://tinesoft.github.io/ngx-cookieconsent/home
const hostname = `${location.protocol}//${location.host}/consent`

export const cookieConfigJson: NgcCookieConsentConfig = {
  cookie: {
    domain: location.hostname, // it is recommended to set your domain, for cookies to work properly
  },
  position: 'bottom', // 'top', 'bottom', 'top-left', 'top-right', 'bottom-left', 'bottom-right'
  theme: 'classic', // 'classic', 'block', 'edgeless'
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'opt-in', //'info', 'opt-in', 'opt-out'

  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{buttonlist}}', //{{compliance}} : default button
  },

  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>,
      <a tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>
      and our
      <a tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </span>
    `,
    buttonlist: `
    <a role="button" tabindex="0" class="cc-btn cc-deny">ปฏิเสธคุกกี้</a>
    <a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow">อนุญาตคุกกี้</a>
    `,
  },

  content: {
    message:
      'เว็บไซต์นี้มีการจัดเก็บคุกกี้เพื่อให้สามารถสร้างประสบการณ์ที่ดียิ่งขึ้นเมื่อท่านกลับเข้ามาใช้งานเว็บไซต์ของเราในครั้งต่อ ๆ ไป\nหากท่านต้องการทราบว่า คุกกี้ที่เราใช้มีประเภทใดบ้าง ท่านสามารถศึกษาได้จากนโยบายการใช้คุกกี้ของเรา ทั้งนี้ หากท่านยอมรับนโยบาย จะหมายความว่าท่านยินยอมให้เราบันทึกและใช้คุกกี้จากอุปกรณ์ที่ท่านใช้ในการเข้าเว็บไซต์ของเรา',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: `${hostname}/cookies`,

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: `${hostname}/privacy`,

    tosLink: 'Terms of Service',
    tosHref: `${hostname}/tos`,

    dismiss: 'อนุญาตคุกกี้',
    deny: 'ปฏิเสธคุกกี้',
    // link: 'อ่านเพิ่มเติม',
    // href: 'https://atlasx.cdg.co.th/',
    policy: 'Cookie Policy',
  },
}
