import { Inject, Injectable } from '@angular/core'
import { take, map } from 'rxjs/operators'

import { AxRequestService, AxResponse, AxWebServiceUrl } from '@atlasx/core/http-service'
import { AxFunctionConfig, AxGeneralConfig, AxLayerConfig, AxServiceConfig, AxSystemAttributes, AxSystemConfig } from '@atlasx/core/configuration'

// import { AxSystemAttributes, AxSystemConfig } from './system.config'
// import { AxFunctionConfig } from './function.config'
// import { AxServiceConfig } from './service.config'
// import { AxLayerConfig } from './layer.config'
// import { AxGeneralConfig } from './general.config'

/**
 * DI service for manage application configuration.
 */
@Injectable()
export class ConfigurationService {
  /**
   * System configures.
   */
  systems: AxSystemConfig | null = null

  /**
   * Function configures.
   */
  functions: AxFunctionConfig | null = null

  /**
   * Service configures.
   */
  services: AxServiceConfig | null = null

  /**
   * Layer configures.
   */
  layers: AxLayerConfig | null = null

  /**
   * General configures.
   */
  general: AxGeneralConfig = null

  constructor(
    private requestService: AxRequestService,
    @Inject(AxWebServiceUrl) private webServiceUrl: string
  ) { }

  /**
   * Retrieved application configuration from web service.
   */
  public load(): Promise<void> {
    return this.requestService
      .request<AxResponse<AxSystemAttributes>>(`${this.webServiceUrl}/api/appconfig`, 'GET')
      .pipe(take(1))
      .pipe(
        map((configResponse) => {
          if (configResponse.success === true) {
            this.systems = new AxSystemConfig(...configResponse.data)
            this.functions = new AxFunctionConfig(...configResponse.data2)
            this.services = new AxServiceConfig(...configResponse.data3)
            this.layers = new AxLayerConfig(...configResponse.data4)
            this.general = new AxGeneralConfig(configResponse.data5)
          }
          return
        })
      )
      .toPromise()
  }
}
