import { Injectable } from '@angular/core'
import { MenuConfigModel } from '../core/domain/menu-config.model'
import { IFunctionConfigRepository } from '../core/repositories/function-config.repository'
import { IFunctionConfigService } from '../core/service/function-config.service'

@Injectable({
  providedIn: 'root',
})
export class FunctionConfigService extends IFunctionConfigService<MenuConfigModel> {
  constructor(private functionConfigRepository: IFunctionConfigRepository<MenuConfigModel>) {
    super()
  }

  getMenuConfig(): MenuConfigModel {
    return this.functionConfigRepository.getMenuConfig()
  }
  getFunctionNameByFunctionId(functionId: string): string {
    return this.functionConfigRepository.getFunctionNameByFunctionId(functionId)
  }
  isFunctionAvailable(functionId: string): boolean {
    return this.functionConfigRepository.isFunctionAvailable(functionId)
  }
}
