import { Injectable } from '@angular/core'
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent'
import { Subscription } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private popupOpenSubscription: Subscription
  private popupCloseSubscription: Subscription
  private initializeSubscription: Subscription
  private statusChangeSubscription: Subscription
  private revokeChoiceSubscription: Subscription
  private noCookieLawSubscription: Subscription
  cookies: any

  loading = false

  constructor(private ccService: NgcCookieConsentService) {}

  getCookiesValue() {
    const value = `; ${document.cookie}`
    const parts = value.split(`; cookieconsent_status=`)
    if (parts.length === 2) {
      this.cookies = parts.pop().split(';').shift()
    } else {
      this.cookies = null
    }
  }

  checkCookiesConsent() {
    this.getCookiesValue()
    if (this.cookies) {
      this.destroyCookiesConsent()
      this.ccService.destroy()
    } else {
      // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log('popupOpen')
      })

      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log('popuClose')
        this.destroyCookiesConsent()
        this.ccService.destroy()
      })

      this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log(`initialize: ${JSON.stringify(event)}`)
      })

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log(`statusChange: ${JSON.stringify(event)}`)
      })

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log(`revokeChoice`)
      })

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log(`noCookieLaw: ${JSON.stringify(event)}`)
      })
    }
  }

  destroyCookiesConsent() {
    if (this.popupOpenSubscription) this.popupOpenSubscription.unsubscribe()
    if (this.popupCloseSubscription) this.popupCloseSubscription.unsubscribe()
    if (this.initializeSubscription) this.initializeSubscription.unsubscribe()
    if (this.statusChangeSubscription) this.statusChangeSubscription.unsubscribe()
    if (this.revokeChoiceSubscription) this.revokeChoiceSubscription.unsubscribe()
    if (this.noCookieLawSubscription) this.noCookieLawSubscription.unsubscribe()
  }
}
