import { Injectable } from '@angular/core'

import { PolicyInfo, PolicyType } from '../core/model/consent'
import { IConsentRepository } from '../core/repositories/consent.repository'
import { IConsentService } from '../core/service/consent.service'


@Injectable({
  providedIn: 'root',
})
export class ConsentService extends IConsentService {

  constructor(private consentRepo: IConsentRepository) {
    super()
  }

  getPolicy(policyType: PolicyType): Promise<PolicyInfo> {
    return this.consentRepo.getPolicy(policyType)
  }
}
