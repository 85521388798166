import { Injectable } from '@angular/core'
import { AxConfigurationService, AxSystemAttributes } from '@atlasx/core/configuration'
import { SystemConfigModel } from 'src/app/core/domain/system-config.model'
import { ISystemConfigRepository } from 'src/app/core/repositories/system-config.repository'
import { systemConfig } from 'src/app/core/config/system.config'
import { ConfigurationService } from 'src/app/service/configuration.service'

@Injectable()
export class SystemConfigRepository extends ISystemConfigRepository<SystemConfigModel> {
  private systemInfo: AxSystemAttributes[] = []

  constructor(private configService: ConfigurationService) {
    super()
    this.loadSystemInfo()
  }

  private loadSystemInfo(): void {
    this.systemInfo = [...this.configService.systems]
  }

  getSystemConfig(): SystemConfigModel[] {
    return systemConfig
  }

  getSystemNameBySystemId(systemId: string): string {
    const system = this.systemInfo.find((s) => s.SYSTEM_ID === systemId)
    return system ? system.SYSTEM_NAME : ''
  }

  getSystemDetailBySystemId(systemId: string): string {
    const system = this.systemInfo.find((s) => s.SYSTEM_ID === systemId)
    return system ? system.SYSTEM_DETAIL : ''
  }

  getSystemUrlBySystemId(systemId: string): string {
    const system = this.systemInfo.find((s) => s.SYSTEM_ID === systemId)
    return system ? system.SYSTEM_URL : ''
  }

  isSystemAvailable(systemId: string): boolean {
    const system = this.systemInfo.find((s) => s.SYSTEM_ID === systemId)
    return !!system
  }
}
