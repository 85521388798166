import { PrimeIcons } from 'primeng/api'
import { MenuConfigModel } from 'src/app/core/domain/menu-config.model'

export const menuConfig: MenuConfigModel = {
  navItemGroup: [
    {
      items: [
        {
          title: 'Home',
          icon: PrimeIcons.HOME,
          url: '/',
          type: 'link',
          showInTablet: true,
        },
        {
          title: 'Product',
          url: 'http://atlasx.cdg.co.th',
          type: 'link',
        },
      ],
      position: 'start',
    },
    {
      items: [
        {
          title: 'Standard Tools',
          functionId: 'STD-01',
          type: 'dropdown',
          dropdownItems: [
            {
              functionId: 'STD-01',
            },
            {
              functionId: 'STD-02',
            },
          ],
        },
        {
          functionId: 'GIS-01',
          type: 'dropdown',
          dropdownItems: [
            {
              functionId: 'GIS-02',
              icon: PrimeIcons.MAP,
              url: '/',
            },
          ],
        },
      ],
      position: 'start',
    },
    {
      items: [
        {
          title: 'Dropdown-1',
          type: 'dropdown',
          showInTablet: true,
          dropdownItems: [
            {
              title: 'Dropdown-1-1',
              url: '/',
            },
          ],
        },
        {
          title: 'Dropdown-2',
          type: 'dropdown',
          dropdownItems: [
            {
              title: 'Dropdown-2-1',
              url: '/',
            },
          ],
        },
      ],
      position: 'end',
    },
  ],
  siderItems: [
    {
      item: {
        title: 'Dashboard',
        icon: PrimeIcons.TH_LARGE,
        url: '/dashboard',
      },
      position: 'start',
    },
    {
      item: {
        title: 'Report',
        icon: PrimeIcons.FILE_PDF,
        subMenu: [
          {
            label: 'Report 1',
            expanded: true,
            children: [
              {
                label: 'Sub Report 1-1',
                data: {
                  url: '/sub-report-1-1',
                },
                type: 'link',
              },
              {
                label: 'Sub Report 1-1',
                expanded: true,
                children: [
                  {
                    label: 'Sub Report 1-2',
                    data: {
                      url: '/sub-report-1-2',
                    },
                    type: 'link',
                  },
                ],
              },
            ],
          },
          {
            label: 'Report 2',
            expanded: true,
            children: [
              {
                label: 'Sub Report 2-1',
                data: {
                  url: '/sub-report-2-1',
                },
                type: 'link',
              },
              {
                label: 'Sub Report 2-1',
                expanded: true,
                children: [
                  {
                    label: 'Sub Report 2-2',
                    data: {
                      url: '/sub-report-2-2',
                    },
                    type: 'link',
                  },
                  {
                    label: 'Sub Report 2-2',
                    data: {
                      url: '/sub-report-2-2',
                    },
                    type: 'link',
                  },
                  {
                    label: 'Sub Report 2-2',
                    data: {
                      url: '/sub-report-2-2',
                    },
                    type: 'link',
                  },
                ],
              },
            ],
          },
        ],
      },
      position: 'start',
    },
    {
      item: {
        title: 'Data List',
        icon: PrimeIcons.FOLDER,
        url: '/mis',
      },
      position: 'start',
    },
    {
      item: {
        title: 'Function List',
        icon: PrimeIcons.COG,
        subMenu: [
          {
            label: 'ระบบภูมิศาสตร์ สารสนเทศ',
            expanded: true,
            children: [
              {
                data: {
                  functionId: 'GIS-01',
                },
                expanded: true,
                children: [
                  {
                    label: 'Simple Search',
                    data: {
                      url: '/gis/simple-search'
                    },
                    type: 'link'
                  },
                  {
                    data: {
                      functionId: 'GIS-02',
                    },
                    children: [
                      {
                        data: {
                          functionId: 'GIS-04',
                          url: 'https://atlasx.cdg.co.th',
                        },
                        type: 'link',
                      },
                    ],
                  },
                  {
                    data: {
                      functionId: 'GIS-03',
                      url: 'https://atlasx.cdg.co.th',
                    },
                    type: 'link',
                  },
                ],
              },
              {
                data: {
                  functionId: 'GIS-05',
                  url: 'https://atlasx.cdg.co.th',
                },
                type: 'link',
              },
            ],
          },
          {
            label: 'ระบบจัดการผู้ใช้งาน',
            expanded: true,
            children: [
              {
                data: {
                  functionId: 'UM-01',
                  url: 'https://atlasx.cdg.co.th',
                },
                type: 'link',
              },
              {
                data: {
                  functionId: 'UM-02',
                  url: 'https://atlasx.cdg.co.th',
                },
                type: 'link',
              },
              {
                data: {
                  functionId: 'UM-03',
                  url: 'https://atlasx.cdg.co.th',
                },
                type: 'link',
              },
            ],
          },
        ],
      },
      position: 'start',
    },
  ],
  mobileNavItems: [
    {
      title: 'Home',
      icon: PrimeIcons.HOME,
      url: '/',
    },
    {
      title: 'Dashboard',
      icon: PrimeIcons.TH_LARGE,
      url: '/dashboard',
    },
    {
      title: 'Report',
      icon: PrimeIcons.FILE_PDF,
      url: '/report',
    },
  ],
  mobileSubMenuItems: [
    {
      label: 'Home',
      data: {
        url: '/',
      },
      type: 'link',
    },
    {
      label: 'About',
      data: {
        url: '/about',
      },
      type: 'link',
    },
    {
      label: 'Dashboard',
      data: {
        url: '/dashboard',
      },
      type: 'link',
    },
    {
      label: 'Report',
      expanded: true,
      children: [
        {
          label: 'Report 1',
          expanded: true,
          children: [
            {
              label: 'Sub Report 1',
              data: {
                url: '/report/2',
              },
              type: 'link',
            },
          ],
        },
        {
          label: 'Report 2',
          children: [
            {
              label: 'Sub Report 2',
              data: {
                url: '/report/2',
              },
              type: 'link',
            },
            {
              label: 'Sub Report 2',
              data: {
                url: '/report/2',
              },
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      label: 'Function List',
      children: [
        {
          label: 'ระบบภูมิศาสตร์ สารสนเทศ',
          expanded: true,
          children: [
            {
              data: {
                functionId: 'GIS-01',
              },
              expanded: true,
              children: [
                {
                  label: 'Simple Search',
                  data: {
                    url: '/gis/simple-search'
                  },
                  type: 'link'
                },
                {
                  data: {
                    functionId: 'GIS-02',
                  },
                  children: [
                    {
                      data: {
                        functionId: 'GIS-04',
                        url: 'https://atlasx.cdg.co.th',
                      },
                      type: 'link',
                    },
                  ],
                },
                {
                  data: {
                    functionId: 'GIS-03',
                    url: 'https://atlasx.cdg.co.th',
                  },
                  type: 'link',
                },
              ],
            },
            {
              data: {
                functionId: 'GIS-05',
                url: 'https://atlasx.cdg.co.th',
              },
              type: 'link',
            },
          ],
        },
        {
          label: 'ระบบจัดการผู้ใช้งาน',
          expanded: true,
          children: [
            {
              data: {
                functionId: 'UM-01',
                url: 'https://atlasx.cdg.co.th',
              },
              type: 'link',
            },
            {
              data: {
                functionId: 'UM-02',
                url: 'https://atlasx.cdg.co.th',
              },
              type: 'link',
            },
            {
              data: {
                functionId: 'UM-03',
                url: 'https://atlasx.cdg.co.th',
              },
              type: 'link',
            },
          ],
        },
      ],
    },
  ],
}
