import { Injectable } from '@angular/core'
import { AxConfigurationService, AxFunctionAttributes } from '@atlasx/core/configuration'
import { standardToolConfig } from 'src/app/core/config/standard-tool.config'
import { StandardToolConfigModel } from 'src/app/core/domain/standard-tool-config.model'
import { IStandardToolConfigRepository } from 'src/app/core/repositories/standard-tool-config.repository'
import { ConfigurationService } from 'src/app/service/configuration.service'

@Injectable()
export class StandardToolConfigRepository extends IStandardToolConfigRepository<StandardToolConfigModel> {
  private functionInfo: AxFunctionAttributes[] = []

  constructor(private configService: ConfigurationService) {
    super()
    this.loadFunctionInfo()
  }

  private loadFunctionInfo(): void {
    this.functionInfo = [...this.configService.functions]
  }

  getStandardToolConfig(): StandardToolConfigModel[] {
    return standardToolConfig
  }

  isFunctionAvailable(functionId: string): boolean {
    return !!this.functionInfo.find((f) => f.FUNCTION_ID === functionId)
  }
}
