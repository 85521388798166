import { Injectable } from '@angular/core'
import { SystemConfigModel } from '../core/domain/system-config.model'
import { ISystemConfigRepository } from '../core/repositories/system-config.repository'
import { ISystemConfigService } from '../core/service/system-config.service'

@Injectable({
  providedIn: 'root',
})
export class SystemConfigService extends ISystemConfigService<SystemConfigModel> {
  constructor(private systemConfigRepository: ISystemConfigRepository<SystemConfigModel>) {
    super()
  }

  getSystemConfig(): SystemConfigModel[] {
    return this.systemConfigRepository.getSystemConfig()
  }

  getSystemNameBySystemId(systemId: string): string {
    return this.systemConfigRepository.getSystemNameBySystemId(systemId)
  }

  getSystemDetailBySystemId(systemId: string): string {
    return this.systemConfigRepository.getSystemDetailBySystemId(systemId)
  }

  getSystemUrlBySystemId(systemId: string): string {
    return this.systemConfigRepository.getSystemUrlBySystemId(systemId)
  }

  isSystemAvailable(systemId: string): boolean {
    return this.systemConfigRepository.isSystemAvailable(systemId)
  }
}
