export abstract class ISystemConfigService<T> {
  /**
   * Get system config from config file
   * @returns array of system config
   */
  abstract getSystemConfig(): T[]

  /**
   * Get system name by system id.
   * @param systemId system id
   * @returns systen name
   */
  abstract getSystemNameBySystemId(systemId: string): string

  /**
   * Get system detail by system id.
   * @param systemId system id
   * @returns system detail
   */
  abstract getSystemDetailBySystemId(systemId: string): string

  /**
   * Get system routing path by system id.
   * @param systemId system id
   * @returns system routing path
   */
  abstract getSystemUrlBySystemId(systemId: string): string

  /**
   * Check system permission by system id.
   * @param systemId system id
   * @returns true if have permission, otherwise false
   */
  abstract isSystemAvailable(systemId: string): boolean
}
