import { SystemConfigModel } from '../domain/system-config.model'

export const systemConfig: SystemConfigModel[] = [
  {
    systemId: 'GIS',
    title: 'ระบบภูมิศาสตร์สารสนเทศ',
    detail: 'ระบบภูมิศาสตร์สารสนเทศ',
    imageUrl: 'assets/images/landing/world.png',
    url: '/gis',
  },
  {
    title: 'ระบบสารสนเทศทั่วไป',
    detail: 'ระบบสารสนเทศทั่วไป',
    imageUrl: 'assets/images/landing/pin.png',
    url: '/mis',
  },
  {
    systemId: 'UM',
    title: 'ระบบจัดการผู้ใช้งาน',
    detail: 'ระบบจัดการผู้ใช้งาน',
    imageUrl: 'assets/images/landing/map.png',
    url: '/um',
  },
  {
    title: 'Lorem ipsum dolor sit amet enim.',
    detail: 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspen disse a pellen.',
    imageUrl: 'assets/images/landing/satellite.png',
    url: '/',
  },
]
