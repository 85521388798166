import { NgModule } from '@angular/core'

import { IConsentRepository } from '../core/repositories/consent.repository'
import { IFunctionConfigRepository } from '../core/repositories/function-config.repository'
import { IProjectRepository } from '../core/repositories/project.repository'
import { IStandardToolConfigRepository } from '../core/repositories/standard-tool-config.repository'
import { ISystemConfigRepository } from '../core/repositories/system-config.repository'

import { IConsentService } from '../core/service/consent.service'
import { IFunctionConfigService } from '../core/service/function-config.service'
import { IProjectService } from '../core/service/project.service'
import { IStandardToolConfigService } from '../core/service/standard-tool-config.service'
import { ISystemConfigService } from '../core/service/system-config.service'

import { ConsentRepository } from '../data/repository/consent.repository'
import { FunctionConfigRepository } from '../data/repository/function-config.repository'
import { ProjectMockRepository } from '../data/repository/project-mock.repository'
import { StandardToolConfigRepository } from '../data/repository/standard-tool-config.repository'
import { SystemConfigRepository } from '../data/repository/system-config.repository'
import { ConfigurationService } from './configuration.service'

import { ConsentService } from './consent.service'
import { FunctionConfigService } from './function-config.service'
import { ProjectService } from './project.service'
import { StandardToolConfigService } from './standard-tool-config.service'
import { SystemConfigService } from './system-config.service'

@NgModule({
  providers: [
    {
      provide: IConsentRepository,
      useClass: ConsentRepository,
    },
    {
      provide: IFunctionConfigRepository,
      useClass: FunctionConfigRepository,
    },
    {
      provide: IProjectRepository,
      useClass: ProjectMockRepository,
    },
    {
      provide: IStandardToolConfigRepository,
      useClass: StandardToolConfigRepository,
    },
    {
      provide: ISystemConfigRepository,
      useClass: SystemConfigRepository,
    },
    {
      provide: IConsentService,
      useClass: ConsentService,
    },
    {
      provide: IFunctionConfigService,
      useClass: FunctionConfigService,
    },
    {
      provide: IProjectService,
      useClass: ProjectService,
    },
    {
      provide: IStandardToolConfigService,
      useClass: StandardToolConfigService,
    },
    {
      provide: ISystemConfigService,
      useClass: SystemConfigService,
    },
    {
      provide: ConfigurationService,
      useClass: ConfigurationService,
    },
  ],
})
export class ServiceModule {}
