import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, type = 'email'): string {
    if (type === 'email') {
      return value.replace(/^(.)(.*)(.@.*)$/,
        (_, a, b, c) => a + b.replace(/./g, '*') + c
      )
    } else {
      return value
    }
  }

}
