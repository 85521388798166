import { PolicyInfo, PolicyType } from '../model/consent'


export abstract class IConsentRepository {
  /**
   * Get consent policy information.
   * @param policyType
   */
  abstract getPolicy(policyType: PolicyType): Promise<PolicyInfo>
}
