<div class="form-container">
    <div class="content-side">
        <div class="header">
            <div class="logo"></div>
            <div class="title">
                <h1 class="sys-name">BMA-TAX</h1>
                <h1 class="owner-th">กรุงเทพมหานคร</h1>
                <h3 class="owner-en">Bangkok Metropolitan Administrator</h3>
            </div>
        </div>

        <div class="app-name" *ngIf="appName">{{appName}}</div>

        <div class="app-detail">
            "ภาษีที่ดินและสิ่งปลูกสร้าง" คืออัตราการเก็บภาษีจากการเข้าทำประโยชน์บนที่ดิน
            เพื่อกระตุ้นให้เกิดการใช้ประโยชน์ในที่ดิน และปรับปรุงปัญหาการจัดเก็บภาษีที่ดินในท้องถิ่นให้มี
            ความทันสมัย โดยมีผลบังคับตั้งแต่ 13 มีนาคม 2562 และเริ่มจัดเก็บภาษีที่ดินและสิ่งปลูกสร้าง
            ตั้งแต่ 1 มกราคม 2563 เป็นครั้งแรก
        </div>
    </div>
    <div class="form-side">
        <div *ngIf="previousPageUrl !== ''" class="btn-back">
            <button pButton type="button" label="กลับ" icon="pi pi-chevron-left" class="p-button-sm"
                [routerLink]="previousPageUrl"></button>
        </div>
        <div class="form-flex">
            <ng-content select="[authForm]"></ng-content>
        </div>
        <div class="bottom-content">
            <ng-content select="[authFooter]"></ng-content>
        </div>
    </div>
</div>

<div class="overlay-image">
    <div class="shadow-corner"></div>
    <div class="grid-image"></div>
</div>